import * as React from "react";

import * as styles from "./ContentColumn.module.css";

type ContentColumnProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const ContentColumn = ({ children, style }: ContentColumnProps) => {
  return (
    <div className={styles.column} style={style || {}}>
      {children}
    </div>
  );
};

export default ContentColumn;
