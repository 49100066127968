import * as React from "react";

import * as styles from "./Banner.module.css";
import UndraggableImage from "../UndraggableImage";

type BannerProps = {
  children?: React.ReactNode;
  bg?: JSX.Element;
  backgroundImg?: string;
  bgStyle?: React.CSSProperties;
  style?: React.CSSProperties;
};

const Banner = ({
  children,
  backgroundImg,
  bgStyle,
  bg,
  style,
}: BannerProps) => {
  let background: React.ReactNode;

  if (bg) {
    background = React.cloneElement(bg, {
      className: styles.bannerBackground,
      style: bgStyle || {},
    });
  } else if (backgroundImg) {
    background = (
      <UndraggableImage
        src={backgroundImg}
        alt=""
        className={styles.bannerBackground}
        style={bgStyle || {}}
      />
    );
  } else {
    background = (
      <div className={styles.bannerBackground} style={bgStyle || {}} />
    );
  }

  return (
    <div className={styles.banner} style={style || {}}>
      {background}

      <div className={styles.bannerContent}>{children}</div>
    </div>
  );
};

export default Banner;
