import * as React from "react";

type UndraggableImageProps = {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
};

const UndraggableImage = ({
  src,
  alt,
  style,
  className,
}: UndraggableImageProps) => {
  // disable responses to events
  const preventEventHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
  };

  return (
    <img
      className={className || ""}
      src={src}
      alt={alt}
      style={style || {}}
      onContextMenu={preventEventHandler}
      onDragStart={preventEventHandler}
    />
  );
};

export default UndraggableImage;
