import * as React from "react";

import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import ContactsBanner from "../components/ui/banners/ContactsBanner";
import ContentColumn from "../components/ui/ContentColumn";

const Contattaci = () => {
  return (
    <>
      <Seo title="Contattaci" description="Lorem ipsum dolor sit amet" />
      <Layout>
        <ContentColumn>
          <h1>Contattaci</h1>
        </ContentColumn>
        <ContactsBanner />
      </Layout>
    </>
  );
};

export default Contattaci;
