import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Banner from "./Banner";
import * as styles from "./ContactsBanner.module.css";
import StyledLink from "../StyledLink";

const ContactsBanner = () => {
  const bg = <StaticImage src="../../../assets/contacts_banner.png" alt="" />;

  return (
    <Banner bg={bg} bgStyle={{ filter: "grayscale(100%)", opacity: "20%" }}>
      <div className={styles.bannerContentContainer}>
        <div className={styles.bannerContent}>
          <h2>Contatti</h2>

          <p>
            Servizio Consumatori: <b>800 800121</b>
            <br />
            lunedì-venerdì 9:00 - 18:00
          </p>

          <p>
            <a
              className={styles.link}
              href="mailto:consumer-service.it@unilever.com"
            >
              consumer-service.it@unilever.com
            </a>
            <br />
            <a className={styles.link} href="https://www.dettofranoi.it">
              www.dettofranoi.it
            </a>
            <br />
            <a className={styles.link} href="https://www.unilever.it">
              www.unilever.it
            </a>
          </p>

          <StyledLink to="/404">Chiama</StyledLink>
        </div>
      </div>
    </Banner>
  );
};

export default ContactsBanner;
