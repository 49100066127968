import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./StyledLink.module.css";

type StyledLinkProps = {
  children: React.ReactNode;
  to: string;
};

const StyledLink = ({ children, to }: StyledLinkProps) => {
  return (
    <Link className={styles.styledLink} to={to}>
      <h4>{children}</h4>
    </Link>
  );
};

export default StyledLink;
